@font-face {
  font-family: "Inter";
  src: local("Inter"), url("assets/Fonts/Inter-Regular.ttf") format("truetype");
}

body {
  padding: 0;
  margin: 0;
  background-color: #F4F8F9;
  font-family: 'Inter';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Inter';
}

a {
  color: inherit;
  text-decoration: none;
}

.text-truncate {
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

/* ::-webkit-scrollbar {
  background-color: #0d2965;
  width: 4px;
  height: 6px;
  border-radius: 16px;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -ms-border-radius: 16px;
  -o-border-radius: 16px;
}

::-webkit-scrollbar-thumb {
  background-color: #2c4a89;
  border-radius: 16px;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -ms-border-radius: 16px;
  -o-border-radius: 16px;
} */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* phone filed css */

.special-label {
  background-color: #f3f3f3 !important;

}

.form-control:focus {
  color: #012c58 !important;
  border: 2px solid #012c58 !important;
  box-shadow: none !important;
}

.react-tel-input .form-control {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.quill {

  /* width: 440px !important; */
  /* max-width: 100% !important; */
}

.ql-container {

  /* width: 450px !important; */
}

.ql-editor {
  min-height: 100px !important;
  overflow-y: scroll !important;
  resize: vertical !important;
  /* text-wrap: nowrap !important */
  /* width: 490px !; */
  word-break: break-all;
}